/* cyrillic-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_a06c33';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_a06c33';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_a06c33 {font-family: '__Inter_a06c33', '__Inter_Fallback_a06c33';font-style: normal
}.__variable_a06c33 {--font-inter: '__Inter_a06c33', '__Inter_Fallback_a06c33'
}

/* vietnamese */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/17e0992af308dbf5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5e34f549e172cb28-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8347d131139854e0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/17e0992af308dbf5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5e34f549e172cb28-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/8347d131139854e0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/17e0992af308dbf5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/5e34f549e172cb28-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Epilogue_95b835';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/8347d131139854e0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Epilogue_Fallback_95b835';src: local("Arial");ascent-override: 71.15%;descent-override: 21.16%;line-gap-override: 0.00%;size-adjust: 111.04%
}.__className_95b835 {font-family: '__Epilogue_95b835', '__Epilogue_Fallback_95b835';font-style: normal
}.__variable_95b835 {--font-epilogue: '__Epilogue_95b835', '__Epilogue_Fallback_95b835'
}

/* Base (Light) Theme */
:root {
  /* Brand Colors */
  --brand-primary: #444ae1;
  --brand-light: #f7f7ff;
  --brand-dark: #363ccb;
  --brand-background: #f6f7ff;

  /* Neutral Colors */
  --neutral-black: #333333;
  --neutral-white: #ffffff;

  /* Gray Scale */
  --gray-lightest: #f9fafb;
  --gray-lighter: #f3f4f6;
  --gray-light: #e5e7eb;
  --gray-medium: #d1d5db;
  --gray-dark: #9ca3af;
  --gray-darker: #6b7280;
  --gray-darkest: #4b5563;

  /* Text Colors */
  --text-body: #1c1f23;
  --text-heading: #111827;
  --text-caption: #6b7280;
  --text-muted: #4b5563;
  --text-placeholder: #9ca3af;
  --text-disabled: #9ca3af;
  --text-link-default: #444ae1;
  --text-link-hover: #383ccb;
  --text-link-active: #2563eb;
  --text-link-visited: #8b5cf6;
  --text-onPrimary: #ffffff;

  /* Background Colors */
  --background-body: #f9fafb;
  --background-surface: #ffffff;
  --background-muted: #f3f4f6;
  --background-hover: #f3f4f6;
  --background-selected: #d1d5db;
  --background-modal: rgba(0, 0, 0, 0.15);
  --background-input: #ffffff;
  --background-menu-active: #f7f7ff;
  --background-pulse: linear-gradient(to right, #d1d5db, #e5e7eb);

  /* Border Colors */
  --border-default: #e5e7eb;
  --border-focus: #333333;
  --border-muted: #e5e7eb;
  --border-input: #d1d5db;

  /* Action Colors */
  --action-primary: #444ae1;
  --action-primary-hover: #383ccb;
  --action-secondary: #333333;
  --action-secondary-hover: #4b5563;
  --action-destructive: #b91c1c;
  --action-destructive-hover: #991b1b;
  --action-success: #065f46;
  --action-disabled: #9ca3af;

  /* Feedback Colors */
  --feedback-success-text: #065f46;
  --feedback-success-icon: #065f46;
  --feedback-success-background: #d1fae5;
  --feedback-success-border: #10b981;
  --feedback-error-text: #b91c1c;
  --feedback-error-icon: #b91c1c;
  --feedback-error-background: #fee2e2;
  --feedback-error-border: #ef4444;
  --feedback-warning-text: #92400e;
  --feedback-warning-icon: #92400e;
  --feedback-warning-background: #fef3c7;
  --feedback-warning-border: #f59e0b;
  --feedback-info-text: #2563eb;
  --feedback-info-icon: #2563eb;
  --feedback-info-background: #dbeafe;
  --feedback-info-border: #93c5fd;

  /* Data Visualization Colors */

  --data-default: #0082ff;

  --data-categorical-1: #11b5ae;
  --data-categorical-2: #4046ca;
  --data-categorical-3: #f68512;
  --data-categorical-4: #de3c82;
  --data-categorical-5: #7e84fa;
  --data-categorical-6: #72e06a;
  --data-categorical-7: #167af3;
  --data-categorical-8: #7326d3;
  --data-categorical-9: #e7c600;
  --data-categorical-10: #cb5d02;
  --data-categorical-11: #008f5d;
  --data-categorical-12: #bce931;

  /* Sequential Scale */
  --data-sequential-scale-1: #fafa37;
  --data-sequential-scale-2: #d6e654;
  --data-sequential-scale-3: #b2d373;
  --data-sequential-scale-4: #88be8c;
  --data-sequential-scale-5: #5fa2a4;
  --data-sequential-scale-6: #3d7eaa;
  --data-sequential-scale-7: #2a60b1;
  --data-sequential-scale-8: #1b3a99;
  --data-sequential-scale-9: #132353;
  --data-sequential-scale-10: #110f39;

  /* Sequential Intensity */
  --data-sequential-intensity-1: #fcf5cd;
  --data-sequential-intensity-2: #f5db95;
  --data-sequential-intensity-3: #e9af7c;
  --data-sequential-intensity-4: #db7d70;
  --data-sequential-intensity-5: #b9577a;
  --data-sequential-intensity-6: #883482;
  --data-sequential-intensity-7: #6a1f78;
  --data-sequential-intensity-8: #4b1277;
  --data-sequential-intensity-9: #2d0b42;
  --data-sequential-intensity-10: #0b0511;

  /* Diverging Progression */
  --data-diverging-progression-1: #6b2c1a;
  --data-diverging-progression-2: #a24b1e;
  --data-diverging-progression-3: #cb7131;
  --data-diverging-progression-4: #e2a05d;
  --data-diverging-progression-5: #f3e1a3;
  --data-diverging-progression-6: #c1e4d2;
  --data-diverging-progression-7: #7eb8b1;
  --data-diverging-progression-8: #4c8c89;
  --data-diverging-progression-9: #2b6262;
  --data-diverging-progression-10: #1b3d3c;

  /* Diverging Heatmap */
  --data-diverging-heatmap-1: #4e142d;
  --data-diverging-heatmap-2: #8b2d4e;
  --data-diverging-heatmap-3: #c15a4a;
  --data-diverging-heatmap-4: #db8b6a;
  --data-diverging-heatmap-5: #f3e1a3;
  --data-diverging-heatmap-6: #bfd4c7;
  --data-diverging-heatmap-7: #7c9eb9;
  --data-diverging-heatmap-8: #4770a3;
  --data-diverging-heatmap-9: #2e4d8c;
  --data-diverging-heatmap-10: #18274e;

  /* Diverging Polarity */
  --data-diverging-polarity-1: #4e142d;
  --data-diverging-polarity-2: #8b2d4e;
  --data-diverging-polarity-3: #c15a7a;
  --data-diverging-polarity-4: #db8b9e;
  --data-diverging-polarity-5: #f3e1f4;
  --data-diverging-polarity-6: #c7bfe2;
  --data-diverging-polarity-7: #9ea7ce;
  --data-diverging-polarity-8: #7080b5;
  --data-diverging-polarity-9: #4d5e99;
  --data-diverging-polarity-10: #2b3d70;

  /* Device State Colors */
  --device-charging-background: #f3e8ff;
  --device-charging-border: #c084fc;
  --device-charging-text: #6b21a8;

  --device-discharging-background: #fffbeb;
  --device-discharging-border: #facc15;
  --device-discharging-text: #78350f;

  --device-heat-background: #fee2e2;
  --device-heat-border: #f87171;
  --device-heat-text: #920000;

  --device-cool-background: #dbeafe;
  --device-cool-border: #60a5fa;
  --device-cool-text: #0334be;

  --device-eco-background: #ddfff1;
  --device-eco-border: #34d399;
  --device-eco-text: #065f46;

  --device-auto-background: #ffedd5;
  --device-auto-border: #fdba74;
  --device-auto-text: #7c2d12;

  --device-on-background: #ccfbf1;
  --device-on-border: #2dd4bf;
  --device-on-text: #0f766e;

  --device-idle-background: #e5e7eb;
  --device-idle-border: #9ca3af;
  --device-idle-text: #374151;

  --device-off-background: #e5e7eb;
  --device-off-border: #9ca3af;
  --device-off-text: #374151;

  --device-unknown-background: #f8f8f8;
  --device-unknown-border: #d1d5db;
  --device-unknown-text: #000000;

  --device-importing-background: #f8f8f8;
  --device-importing-border: #d1d5db;
  --device-importing-text: #000000;

  --device-exporting-background: #f8f8f8;
  --device-exporting-border: #d1d5db;
  --device-exporting-text: #000000;

  /* Skeleton Colors */
  --skeleton-base: #e5e7eb;
  --skeleton-highlight: #f3f4f6;
  --skeleton-wave: rgba(255, 255, 255, 0.3);
}

/* Dark Theme */
body.dark-theme {
  /* Brand Colors */
  --brand-primary: #b4b9ff;
  --brand-light: #c4c4ff;
  --brand-dark: #a8aeff;
  --brand-background: #3a3f63;

  /* Neutral Colors */
  --neutral-black: #e5e7eb;
  --neutral-white: #151a30;

  /* Gray Scale */
  --gray-lightest: #1f2937;
  --gray-lighter: #374151;
  --gray-light: #4b5563;
  --gray-medium: #6b7280;
  --gray-dark: #9ca3af;
  --gray-darker: #d1d5db;
  --gray-darkest: #f3f4f6;

  /* Text Colors */
  --text-body: #ededef;
  --text-heading: #ffffff;
  --text-caption: #d1d5db;
  --text-muted: #9ca3af;
  --text-placeholder: #9ca3af;
  --text-disabled: #9ca3af;
  --text-link-default: #b4b9ff;
  --text-link-hover: #c0c5ff;
  --text-link-active: #b4b9ff;
  --text-link-visited: #c0c5ff;
  --text-onPrimary: #1f2937;

  /* Background Colors */
  --background-body: #151a30;
  --background-surface: #1a2038;
  --background-muted: #374151;
  --background-hover: #4b5563;
  --background-selected: #6b7280;
  --background-modal: rgba(255, 255, 255, 0.12);
  --background-input: rgba(255, 255, 255, 0.05);
  --background-menu-active: #222b44;
  --background-pulse: linear-gradient(to right, #202c4c, #1a2038);

  /* Border Colors */
  --border-default: #2e3a59;
  --border-focus: #e5e7eb;
  --border-muted: #4b5563;
  --border-input: #6b7280;

  /* Action Colors */
  --action-primary: #7a8ee1;
  --action-primary-hover: #6a6fcc;
  --action-secondary: #e5e7eb;
  --action-secondary-hover: #f3f4f6;
  --action-destructive: #e55a5a;
  --action-destructive-hover: #d12c2c;
  --action-success: #d1fae5;
  --action-disabled: #9ca3af;

  /* Feedback Colors */
  --feedback-success-text: #d1fae5;
  --feedback-success-icon: #d1fae5;
  --feedback-success-background: #065f46;
  --feedback-success-border: #10b981;
  --feedback-error-text: #fee2e2;
  --feedback-error-icon: #fee2e2;
  --feedback-error-background: #b91c1c;
  --feedback-error-border: #ef4444;
  --feedback-warning-text: #fef3c7;
  --feedback-warning-icon: #fef3c7;
  --feedback-warning-background: #92400e;
  --feedback-warning-border: #f59e0b;
  --feedback-info-text: #60a5fa;
  --feedback-info-icon: #60a5fa;
  --feedback-info-background: #1e3160;
  --feedback-info-border: #2563eb;

  /* Data Visualization Colors remain the same in dark mode since they are absolute values */
  /* Device State Colors */
  --device-charging-background: #581c87;
  --device-charging-border: #a855f7;
  --device-charging-text: #e9d5ff;

  --device-discharging-background: #78350f;
  --device-discharging-border: #eab308;
  --device-discharging-text: #fef3c7;

  --device-heat-background: #7f1d1d;
  --device-heat-border: #ef4444;
  --device-heat-text: #fee2e2;

  --device-cool-background: #1e3a8a;
  --device-cool-border: #3b82f6;
  --device-cool-text: #dbeafe;

  --device-eco-background: #064e3b;
  --device-eco-border: #10b981;
  --device-eco-text: #d1fae5;

  --device-auto-background: #7c2d12;
  --device-auto-border: #fb923c;
  --device-auto-text: #ffedd5;

  --device-on-background: #134e4a;
  --device-on-border: #14b8a6;
  --device-on-text: #ccfbf1;

  --device-idle-background: #374151;
  --device-idle-border: #6b7280;
  --device-idle-text: #e5e7eb;

  --device-off-background: #374151;
  --device-off-border: #6b7280;
  --device-off-text: #e5e7eb;

  --device-unknown-background: #1f2937;
  --device-unknown-border: #6b7280;
  --device-unknown-text: #ffffff;

  --device-importing-background: #1f2937;
  --device-importing-border: #6b7280;
  --device-importing-text: #ffffff;

  --device-exporting-background: #1f2937;
  --device-exporting-border: #6b7280;
  --device-exporting-text: #ffffff;

  /* Skeleton Colors */
  --skeleton-base: #2e3a59;
  --skeleton-highlight: #374151;
  --skeleton-wave: rgba(255, 255, 255, 0.1);
}

